import { FC, useContext, useEffect, useState } from 'react';
import { Calendar, CalendarDateTemplateEvent } from 'primereact/calendar';
import { DateTime, DateTime as luxonDateTime, Duration } from 'luxon';
import { useDispatch, useSelector } from 'react-redux';
import { AvailabilityProvider, Loader } from 'concert-ui-library';
import { MultiSelect } from 'primereact/multiselect';
import { RadioButton } from 'primereact/radiobutton';
import {
    AvailabilityProviderDetails,
    AvailabilitySlotDetails,
    ExtendedTimeslot,
    setRefineAvailabilitySelectedOptions,
    setRefineOptions,
} from './slice';
import { selectRefineAvailabilitySelectedOptions, selectRefineOptions } from './selector';
import { SchedulerTimeslots } from './scheduler-timeslots';
import { SchedulingDateFunctions } from '../../../services/scheduling-date-fns';
import { durationSelectItems } from '../constants';
import { SchedulingRequest } from '../slice';
import { Episode } from '../../episodes/slice';
import { User } from '../../user/slice';
import { isFilterComplete, selectSchedulerFilter } from '../scheduler-filter/selector';
import { AvailabilityWhoFilterType } from '../scheduler-filter/slice';
import { buildSchedulingRequest } from './availability-request-builder';
import { baseTimeslotFilters, createRefineOptionsList, refineTimeSlots } from './scheduler-timeslots-refinement';
import { selectGroupByUserSetting } from '../scheduler-view-settings/selector';
import { extractSlot, SlotForRender } from './scheduler-transform';
import {
    AvailabilityInput,
    AvailabilitySettingByDay,
    DateAvailability,
    Maybe,
    useLazyScheduleAvailabilityQuery,
    useLazyGetPatientAvailabilitySettingsQuery,
} from '../../../services/graphql/generated';
import calculateUtilization from '../user-availability-info/utiization-calc';
import { ErrorConstants } from '../../error/constants';
import { NotificationContext } from '../../../notification-context';
import { AVAILABILITY_ERROR_MESSAGES } from './constants';
import { isDevelopment } from '../../../util';
import { PreferredDaysAndTimes } from '../../settings/patient-avalability-settings/preferred-days-and-times.component';

export type SchedulerSelectorProps = {
    onSlotSelected: (selectedSchedulingRequest: SchedulingRequest, selectSlot: AvailabilitySlotDetails) => void;
    initialSchedulingRequest: SchedulingRequest;
};

export const SchedulerSelector: FC<SchedulerSelectorProps> = ({ onSlotSelected, initialSchedulingRequest }) => {
    const dispatch = useDispatch();
    const filter = useSelector(selectSchedulerFilter);
    const filterComplete = useSelector(isFilterComplete);
    const [selectedSchedulingRequest, setSelectedSchedulingRequest] = useState(initialSchedulingRequest);
    const [patientHasNoMatchingAge, setPatientHasNoMatchingAge] = useState(false);
    const [patientHasNoAge, setPatientHasNoAge] = useState(false);
    const [hasAgeFilterApplied, setHasAgeFilterApplied] = useState(false);
    const startingYearToShow = luxonDateTime.now().year - 10;
    const endingYearToShow = luxonDateTime.now().year + 10;
    const isGroupedByState = useSelector(selectGroupByUserSetting);
    const notificationContext = useContext(NotificationContext);
    const currentDateTime = DateTime.now().toLocal().startOf('day');
    const [
        scheduleAvailabilityQuery,
        { data: availability, isFetching: isAvailabilityLoading, isError: isAvailabilityError },
    ] = useLazyScheduleAvailabilityQuery();
    const shouldUtilizationBeShownOnSlot = (whoFilter: User[] | null, isGroupedByUser: boolean) => {
        return whoFilter === null || !whoFilter?.[0] || !isGroupedByUser;
    };
    const [getPatientSettings, { data: patientAvailability, isFetching: isPatientAvailabilityLoading }] =
        useLazyGetPatientAvailabilitySettingsQuery();
    const [showUtilizationOnSlot, setShowUtilizationOnSlot] = useState(
        shouldUtilizationBeShownOnSlot(filter?.user, isGroupedByState),
    );

    const ageFilterValues = [
        baseTimeslotFilters.ages6To12.value,
        baseTimeslotFilters.ages13To17.value,
        baseTimeslotFilters.ages18Plus.value,
    ];

    const teamFilterMinutesDefaultDuration = 60;
    const userFilterMinutesDefaultDuration = 30;

    const minDate = new Date();
    const getPatientSettingsFromFilter = () => {
        getPatientSettings({
            request: { userIds: [filter.episode?.patientId ?? ''] as string[] },
        });
    };
    useEffect(() => {
        if (!filter.episode || !filterComplete) {
            return;
        }

        let timezone = SchedulingDateFunctions.getUsersTimezone();
        if (filter.episode.timezone !== '') {
            timezone = filter.episode.timezone;
        }
        const schedulingRequest = buildSchedulingRequest(
            selectedSchedulingRequest.selectedDate,
            filter.who === AvailabilityWhoFilterType.Team
                ? teamFilterMinutesDefaultDuration
                : userFilterMinutesDefaultDuration,
            timezone,
            selectedSchedulingRequest.timeSlotStartTime,
        );
        setSelectedSchedulingRequest(schedulingRequest);
        setShowUtilizationOnSlot(shouldUtilizationBeShownOnSlot(filter?.user, isGroupedByState));
        if (filter?.episode) {
            const refineOptionsList = createRefineOptionsList(filter?.episode);
            dispatch(
                setRefineOptions(
                    refineOptionsList.map((option) => {
                        return { name: option.nameFunction(), value: option.value };
                    }),
                ),
            );
        }
        checkPatientAge();
        makeAvailabilityRequest(schedulingRequest);
        getPatientSettingsFromFilter();
    }, [filter, filterComplete]);

    const onSelectedDayChange = (dateSelected: Date) => {
        const schedulingRequest = buildSchedulingRequest(
            dateSelected,
            selectedSchedulingRequest.duration,
            selectedSchedulingRequest.timezone,
            selectedSchedulingRequest.timeSlotStartTime,
        );
        setSelectedSchedulingRequest(schedulingRequest);
        makeAvailabilityRequest(schedulingRequest);
    };

    useEffect(() => {
        if (isAvailabilityError) {
            (notificationContext as any).showError(
                `${AVAILABILITY_ERROR_MESSAGES.ERROR_GETTING_AVAILABILITY} ${ErrorConstants.UserCheckMessage}`,
            );
        }
    }, [availability, isAvailabilityError]);

    useEffect(() => {
        if (!isAvailabilityLoading && !isAvailabilityError) {
            setShowUtilizationOnSlot(shouldUtilizationBeShownOnSlot(filter?.user, isGroupedByState));
            refineSlots(refineAvailabilitySelectedOptions);
        }
    }, [isAvailabilityLoading, isGroupedByState, isAvailabilityError, patientAvailability]);

    const onSelectedTimeSlotChange = (slotDuration: number) => {
        const schedulingRequest = buildSchedulingRequest(
            selectedSchedulingRequest.selectedDate,
            slotDuration,
            selectedSchedulingRequest.timezone,
            selectedSchedulingRequest.timeSlotStartTime,
        );
        setSelectedSchedulingRequest(schedulingRequest);
        makeAvailabilityRequest(schedulingRequest);
    };

    const selectTimeSlotForBooking = (timeSlotSelected: string, providerFilter?: string) => {
        const schedulingRequest = buildSchedulingRequest(
            selectedSchedulingRequest.selectedDate,
            selectedSchedulingRequest.duration,
            selectedSchedulingRequest.timezone,
            timeSlotSelected,
        );

        const nowForRequestedTz = DateTime.now().setZone(schedulingRequest.timezone);
        if (schedulingRequest.startDateTime <= nowForRequestedTz) {
            notificationContext.showError(AVAILABILITY_ERROR_MESSAGES.APPOINTMENT_NO_LONGER_AVAILABLE);

            makeAvailabilityRequest(selectedSchedulingRequest);
            return;
        }

        setSelectedSchedulingRequest(schedulingRequest);
        onSlotSelected(
            schedulingRequest,
            extractSlot(refinedSlots, schedulingRequest.selectedTimeSlot, isGroupedByState, providerFilter),
        );
    };

    const makeAvailabilityRequest = (schedulingRequest: SchedulingRequest) => {
        const dateSelectedLuxon = schedulingRequest.selectedLuxonDate;
        if (filter.episode == null || dateSelectedLuxon === null) {
            return;
        }

        const providers = (filter.who === AvailabilityWhoFilterType.User ? filter.user : null) || null;

        const availabilityRequest: AvailabilityInput = {
            startDateTime: dateSelectedLuxon.toUTC().toJSON(),
            endDateTime: dateSelectedLuxon
                .toUTC()
                .plus(Duration.fromObject({ hours: 24 }))
                .toJSON(),
            whoFilterType: filter.who || AvailabilityWhoFilterType.User,
            practiceId: (filter.who === AvailabilityWhoFilterType.Team ? filter.practice?.id : null) || null,
            slotDuration: schedulingRequest.duration.minutes,
            languageKey: (filter.who === AvailabilityWhoFilterType.Team ? filter.language : null) || null,
            providers: providers === null ? [] : providers,
            tzTimeZone: schedulingRequest.timezone,
            episodeId: filter.episode.id,
            episodePracticeId: filter.episode.practiceId,
        };
        scheduleAvailabilityQuery({ request: availabilityRequest });
    };

    const refineOptions = useSelector(selectRefineOptions);
    const refineAvailabilitySelectedOptions = useSelector(selectRefineAvailabilitySelectedOptions);
    const [refinedSlots, setRefinedSlots] = useState<User[] | SlotForRender[]>([]);
    useEffect(() => {
        checkAgeFilterApplied(refineAvailabilitySelectedOptions);
    }, [refineAvailabilitySelectedOptions]);

    const checkPatientAge = () => {
        let noAge = false;
        let noMatchingAge = false;
        if (filter.who === AvailabilityWhoFilterType.Team) {
            if (!filter.episode?.patientAge) {
                noAge = true;
            } else if (filter.episode.patientAge < 6) {
                noMatchingAge = true;
            }
        }
        setPatientHasNoMatchingAge(noMatchingAge);
        setPatientHasNoAge(noAge);
    };
    const checkAgeFilterApplied = (refineSelectedOptions: string[]) => {
        let ageFilterApplied = false;
        for (const optionValue of refineSelectedOptions) {
            if (ageFilterValues.includes(optionValue)) {
                ageFilterApplied = true;
                break;
            }
        }
        setHasAgeFilterApplied(ageFilterApplied);
    };
    const isInvalidAge = () => !hasAgeFilterApplied && (patientHasNoMatchingAge || patientHasNoAge);

    const changeSlotsRefinementSelections = (refineSelectedoptions: string[]) => {
        dispatch(setRefineAvailabilitySelectedOptions(refineSelectedoptions));
        checkPatientAge();
        refineSlots(refineSelectedoptions);
    };

    const convertAppSyncSimulatorOutputToAppSync = (
        originalScheduleAvailability: Maybe<DateAvailability>,
    ): Maybe<DateAvailability> => {
        if (!originalScheduleAvailability) return originalScheduleAvailability;

        const convertedScheduleAvailability = JSON.parse(JSON.stringify(originalScheduleAvailability));
        if (convertedScheduleAvailability?.timeslots) {
            for (let i = 0; i < convertedScheduleAvailability.timeslots.length; ++i) {
                const timeslot = convertedScheduleAvailability.timeslots[i];
                if (timeslot) {
                    timeslot.time = timeslot.time?.replace(/\.000Z$/, '');
                }
            }
        }
        return convertedScheduleAvailability;
    };

    const refineSlots = (refineSelectedOptions: string[]) => {
        let scheduleAvailability: Maybe<DateAvailability> = availability?.scheduleAvailability?.[0] || null;

        if (isDevelopment()) {
            scheduleAvailability = convertAppSyncSimulatorOutputToAppSync(scheduleAvailability);
        }

        if (!scheduleAvailability) {
            setRefinedSlots([]);
            return;
        }

        const slots = [...(scheduleAvailability.timeslots as ExtendedTimeslot[])];

        const availabilityRequestDate = scheduleAvailability.date;
        const nowForRequestedTz = DateTime.now().setZone(selectedSchedulingRequest.timezone);

        const spreadSlots: ExtendedTimeslot[] = [];
        slots.forEach((timeslot: ExtendedTimeslot) => {
            // filter out timeslots that have already passed
            const startDuration = Duration.fromISOTime(timeslot.time || '');
            const currentSlotRequestedTz = DateTime.fromISO(availabilityRequestDate, { zone: 'utc' })
                .setZone(selectedSchedulingRequest.timezone, { keepLocalTime: true })
                .set({ hour: startDuration.hours, minute: startDuration.minutes });
            if (currentSlotRequestedTz > nowForRequestedTz) {
                spreadSlots.push({
                    ...timeslot,
                    providerAvailability:
                        timeslot.providerAvailability?.map((providerAvailability: AvailabilityProviderDetails) => {
                            return {
                                ...providerAvailability,
                                utilization: calculateUtilization(
                                    {
                                        ...providerAvailability.provider,
                                        acceptingNewPatients: providerAvailability?.acceptingNewPatients,
                                        notAcceptingType: providerAvailability?.notAcceptingType,
                                    },
                                    filter.episode,
                                ),
                            };
                        }) || [],
                });
            }
        });
        const byDay = patientAvailability?.getAvailabilitySettings?.general?.byDay;
        const { selectedDate } = selectedSchedulingRequest;
        const selectedDatetime = DateTime.local(
            selectedDate.getFullYear(),
            selectedDate.getMonth() + 1,
            selectedDate.getDate(),
        );
        const filteredSlots = refineTimeSlots(
            refineSelectedOptions,
            filter.episode as Episode,
            spreadSlots,
            isGroupedByState,
            selectedSchedulingRequest.selectedLuxonDate,
            getPatientPreferredSlotsInSelectedDate(byDay, selectedDatetime),
            selectedSchedulingRequest.duration,
        );
        setRefinedSlots(filteredSlots);
    };

    const renderAvailabilitySlots = () => {
        if (isAvailabilityLoading) {
            return <Loader />;
        }
        if (filter.episode == null) {
            return <div>Please Select An Episode/Patient to see availability</div>;
        }
        return (
            <SchedulerTimeslots
                selectedDate={selectedSchedulingRequest.selectedLuxonDate}
                slots={refinedSlots}
                displayUtilizationOnSlot={showUtilizationOnSlot}
                onScheduleTimeSlotRequest={selectTimeSlotForBooking}
            />
        );
    };

    const getPatientPreferredSlotsInSelectedDate = (
        preferredAvailability: AvailabilitySettingByDay[] | undefined,
        convertedDate: DateTime,
    ): AvailabilitySettingByDay | undefined => {
        return preferredAvailability?.find((d: AvailabilitySettingByDay) => d.day === convertedDate.weekdayLong);
    };

    const dateTemplate = (date: CalendarDateTemplateEvent) => {
        // date.month is 0 index based, Luxon's is 1 based
        const convertedDate = DateTime.local(date.year, date.month + 1, date.day);
        const byDay = patientAvailability?.getAvailabilitySettings?.general?.byDay;
        const slotsOnThisDate = byDay ? getPatientPreferredSlotsInSelectedDate(byDay, convertedDate) : null;
        if (convertedDate >= currentDateTime && slotsOnThisDate && slotsOnThisDate.timeslots.length > 0) {
            return <strong>{date.day}</strong>;
        }

        return date.day;
    };

    return (
        <div className="scheduler-time-selector">
            <div className="calendar-duration-pref-container">
                <div className="duration-container">
                    {durationSelectItems.map((duration) => {
                        return (
                            <div key={duration.key}>
                                <RadioButton
                                    inputId={duration.key}
                                    name="duration"
                                    value={duration}
                                    onChange={(e) => onSelectedTimeSlotChange(e.value.durationMin)}
                                    checked={duration.durationMin === selectedSchedulingRequest.duration.minutes}
                                />
                                <label htmlFor={duration.key}>{duration.name}</label>
                            </div>
                        );
                    })}
                </div>
                {!isPatientAvailabilityLoading && (
                    <Calendar
                        value={selectedSchedulingRequest.selectedDate}
                        inline
                        monthNavigator
                        yearNavigator
                        showButtonBar
                        minDate={minDate}
                        disabled={isAvailabilityLoading}
                        yearRange={`${startingYearToShow}:${endingYearToShow}`}
                        onChange={(e) => onSelectedDayChange(e.value as Date)}
                        dateTemplate={dateTemplate}
                        selectOtherMonths
                    />
                )}
                <AvailabilityProvider>
                    <PreferredDaysAndTimes
                        patientId={filter.episode?.patientId as string}
                        patientAvailability={patientAvailability?.getAvailabilitySettings?.general ?? null}
                        refreshPatientSettings={getPatientSettingsFromFilter}
                        isAvailabilityLoading={isPatientAvailabilityLoading}
                    />
                </AvailabilityProvider>
            </div>
            <div className="availability-slots">
                <MultiSelect
                    aria-label="Refine availability results"
                    options={refineOptions}
                    optionLabel="name"
                    optionValue="value"
                    value={refineOptions.length ? refineAvailabilitySelectedOptions : []}
                    placeholder="Refine your results"
                    onChange={(e) => changeSlotsRefinementSelections(e.value)}
                    display="chip"
                    className={isInvalidAge() ? `refine-slot-select invalid-age` : `refine-slot-select`}
                    disabled={!filter.episode}
                />

                {patientHasNoMatchingAge && !hasAgeFilterApplied && (
                    <span className="invalid-age error">
                        No matching age range was selected since patient age &lt; 6.
                    </span>
                )}
                {patientHasNoAge && !hasAgeFilterApplied && (
                    <span className="invalid-age error">
                        No matching age range was selected since patient birthdate is missing.
                    </span>
                )}

                {renderAvailabilitySlots()}
            </div>
        </div>
    );
};
