import { Button } from 'primereact/button';
import { ChangeEvent, FormEvent, FunctionComponent, Fragment, useRef, useState, useEffect } from 'react';
import { InputText } from 'primereact/inputtext';
import { classNames } from 'primereact/utils';
import * as QueryString from 'query-string';
import { useHistory, useLocation } from 'react-router-dom';
import { Editor, EditorTextChangeEvent } from 'primereact/editor';
import Quill from 'quill';
import Delta, { Op } from 'quill-delta';
import MagicUrl from 'quill-magic-url';
import { InputNumber, InputNumberValueChangeEvent } from 'primereact/inputnumber';
import { ROUTES, ROUTE_PARAMS } from '../../constants';

Quill.register('modules/autoLink', MagicUrl);

export interface Episode {
    id: string;
    patientId: string | null;
    clinicianId: string | null;
}
export interface MessageSenderProps {
    isNewConversation: boolean;
    handleMessageSubmission: (formData: MessageForm) => void;
    shouldClearForm: boolean;
    isFormVisible: boolean;
    disabled: boolean;
    formValue?: MessageForm | null;
}
export interface MessageForm {
    message: string;
    topic: string | undefined;
    clinicalNotes: string;
    episode: Episode | null;
    minutes: number | undefined;
    isValid: boolean;
}

export interface ConversationContext {
    episodeId: string;
    clinicianId: string;
    clinicianName?: string;
    episodeName?: string;
    patientName: string;
    patientId: string;
    topic?: string;
}

const TopicMaxLength = 100;
const MessageAndClinicalNoteMaxLength = 2000;
const MaxMessageMinutes = 120;
const MinMessageMinutes = 0.01;

export const MessageSender: FunctionComponent<MessageSenderProps> = ({
    isNewConversation,
    handleMessageSubmission,
    isFormVisible,
    disabled,
    formValue,
    shouldClearForm,
}) => {
    const EMPTY_FORM: MessageForm = {
        message: '',
        clinicalNotes: '',
        topic: '',
        minutes: undefined,
        episode: null,
        isValid: false,
    };

    const location = useLocation<ConversationContext | null | undefined>();
    const conversationContext = location.state;
    const history = useHistory();
    const { episodeId, conversationId } = QueryString.parse(location.search);
    const editorRef = useRef<Editor | null>(null);
    const allowedElements = ['bold', 'italic', 'underline', 'link', 'list', 'list-item'];

    const handleEditorRef = (instance: Editor | null) => {
        if (instance) {
            editorRef.current = instance;
            const quill = instance.getQuill() as Quill;
            if (!quill) return;

            quill.clipboard.addMatcher(Node.ELEMENT_NODE, (node: Node, delta: Delta) => {
                if (node.nodeName === 'IMG') return new Delta();

                const ops: Delta['ops'] = [];
                delta.ops.forEach((op: Op) => {
                    if (typeof op.insert === 'string') {
                        if (op.attributes) {
                            const filteredAttributes = Object.keys(op.attributes)
                                .filter((attr) => allowedElements.includes(attr))
                                .reduce((obj, key) => {
                                    obj[key] = op.attributes?.[key];
                                    return obj;
                                }, {});

                            ops.push({
                                insert: op.insert,
                                attributes: Object.keys(filteredAttributes).length > 0 ? filteredAttributes : undefined,
                            });
                        } else {
                            ops.push(op);
                        }
                    }
                });

                delta.ops = ops;
                return delta;
            });
        }
    };

    const cancelMessageSubmission = () => {
        history.push({
            pathname: ROUTES.MESSAGING,
            search: `?${ROUTE_PARAMS.EPISODE_ID}=${episodeId}`,
            state: {
                refresh: true,
            },
        });
    };
    if (!conversationId && !conversationContext) cancelMessageSubmission();
    const [form, setForm] = useState<MessageForm>(() => {
        if (shouldClearForm) {
            return EMPTY_FORM;
        }
        return (
            formValue ??
            ({
                episode: {
                    id: conversationContext?.episodeId,
                    clinicianId: conversationContext?.clinicianId,
                    patientId: conversationContext?.patientId,
                },
            } as MessageForm)
        );
    });
    const [isFormSubmitted, setIsFormSubmitted] = useState<boolean>(false);

    const modules = {
        autoLink: true,
        clipboard: {
            matchVisual: false,
        },
        keyboard: {
            bindings: {
                indent: null,
            },
        },
    };

    const validateForm = (): boolean => {
        if (isNewConversation && (isEmpty(form.topic) || !form.episode)) {
            return false;
        }
        const baseValidationInvalid = isEmpty(form.message) || isMinutesInvalid() || isEmpty(form.clinicalNotes);
        return !baseValidationInvalid;
    };

    const isEmpty = (value: string | undefined | null): boolean => {
        return !value || value.trim() === '';
    };

    const isMinutesInvalid = (): boolean => {
        return !form.minutes || form.minutes < MinMessageMinutes || form.minutes > MaxMessageMinutes;
    };

    const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setIsFormSubmitted(true);
        const validatedForm = { ...form, isValid: validateForm() };
        handleMessageSubmission(validatedForm);
    };

    const handleInputChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const { name, value } = e.target;
        setForm({ ...form, [name]: value });
    };

    const handleNumberInputChange = (e: InputNumberValueChangeEvent) => {
        const { name, value } = e.target;
        setForm({ ...form, [name]: value });
    };
    const handleEditorChange = (e: EditorTextChangeEvent, fieldName: string) => {
        const { htmlValue, textValue } = e;
        const isTextEmpty = (textValue && textValue.trim()) === '';
        setForm({ ...form, [fieldName]: isTextEmpty ? '' : htmlValue ?? '' });
    };
    const renderEditorHeader = () => {
        return (
            <span className="ql-formats">
                <Button className="ql-bold p-button-bold" aria-label="Bold" />
                <Button className="ql-italic p-button-italic" aria-label="Italic" />
                <Button className="ql-underline p-button-underline" aria-label="Underline" />
                <Button type="button" className="ql-list" value="ordered" aria-label="Ordered list" />
                <Button type="button" className="ql-list" value="bullet" aria-label="Bullet list" />
            </span>
        );
    };
    const renderForm = (): JSX.Element[] => {
        const formData: JSX.Element[] = [];
        if (isNewConversation) {
            formData.push(
                <Fragment key="owner-topic">
                    <div className="p-inputgroup flex-1">
                        <InputText
                            value={form?.topic}
                            placeholder={`Topic (up to ${TopicMaxLength} characters)`}
                            name="topic"
                            onChange={handleInputChange}
                            maxLength={TopicMaxLength}
                            invalid={isFormSubmitted && isEmpty(form.topic)}
                        />
                    </div>
                </Fragment>,
            );
        }
        formData.push(
            <Fragment key="message-clinical-note-submit">
                <div className="p-inputgroup flex-1">
                    <Editor
                        ref={handleEditorRef}
                        value={form.message}
                        name="message"
                        headerTemplate={renderEditorHeader()}
                        placeholder={`Message (up to ${MessageAndClinicalNoteMaxLength} characters)`}
                        aria-label="message"
                        data-testid="message"
                        showHeader
                        onTextChange={(e: EditorTextChangeEvent) => handleEditorChange(e, 'message')}
                        maxLength={MessageAndClinicalNoteMaxLength}
                        modules={modules}
                        className={classNames({
                            'p-invalid': isFormSubmitted && isEmpty(form.message),
                        })}
                    />
                </div>
                <div className="p-inputgroup flex-1">
                    <Editor
                        ref={handleEditorRef}
                        value={form.clinicalNotes}
                        name="clinicalNote"
                        aria-label="clinicalNote"
                        headerTemplate={renderEditorHeader()}
                        placeholder={`Clinical Note (up to ${MessageAndClinicalNoteMaxLength} characters)`}
                        showHeader
                        onTextChange={(e: EditorTextChangeEvent) => handleEditorChange(e, 'clinicalNotes')}
                        maxLength={MessageAndClinicalNoteMaxLength}
                        modules={modules}
                        data-testid="clinicalNote"
                        className={classNames({
                            'p-invalid': isFormSubmitted && isEmpty(form.clinicalNotes),
                        })}
                    />
                </div>

                <div className="p-inputgroup flex-1 message-send-buton">
                    <InputNumber
                        onValueChange={handleNumberInputChange}
                        value={form.minutes}
                        placeholder={`Minutes (up to ${MaxMessageMinutes})`}
                        name="minutes"
                        maxFractionDigits={2}
                        invalid={isFormSubmitted && isMinutesInvalid()}
                    />{' '}
                    <Button disabled={disabled} className="p-button-secondary" label="Submit" type="submit" />
                    <Button
                        disabled={disabled}
                        className="p-button-link"
                        label="Cancel"
                        type="button"
                        onClick={cancelMessageSubmission}
                    />
                </div>
            </Fragment>,
        );
        return formData;
    };

    return (
        <div className="message-sender-container">
            <form className={isFormVisible ? 'visible-form' : ''} onSubmit={handleSubmit}>
                {isFormVisible && renderForm()}
            </form>
        </div>
    );
};
