import { datadogRum } from '@datadog/browser-rum';
import { FunctionComponent, useEffect } from 'react';
import { DatadogSettings, getDatadogConfig } from '../../config';

const DEFAULT_SESSION_SAMPLE_RATE = 100;
const DEFAULT_SESSION_REPLAY_SAMPLE_RATE = 0;

const Rum: FunctionComponent = () => {
    useEffect(() => {
        const datadogConfig: DatadogSettings = getDatadogConfig();
        const parsedSessionSampleRate = Number.parseInt(datadogConfig.sessionSampleRate, 10);
        const parsedSessionReplaySampleRate = Number.parseInt(datadogConfig.sessionReplaySampleRate, 10);
        datadogRum.init({
            applicationId: datadogConfig.applicationId,
            clientToken: datadogConfig.clientToken,
            site: datadogConfig.site,
            service: datadogConfig.service,
            env: datadogConfig.env,
            // Specify a version number to identify the deployed version of your application in Datadog
            version: datadogConfig.version,
            sessionSampleRate: Number.isNaN(parsedSessionSampleRate)
                ? DEFAULT_SESSION_SAMPLE_RATE
                : parsedSessionSampleRate,
            sessionReplaySampleRate: Number.isNaN(parsedSessionReplaySampleRate)
                ? DEFAULT_SESSION_REPLAY_SAMPLE_RATE
                : parsedSessionReplaySampleRate,
            trackUserInteractions: datadogConfig.trackUserInteractions,
        });
    }, []);
    return null;
};

export default Rum;
