import { api } from './concert-base-api';

export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
    ID: { input: string; output: string };
    String: { input: string; output: string };
    Boolean: { input: boolean; output: boolean };
    Int: { input: number; output: number };
    Float: { input: number; output: number };
    AWSDate: { input: string; output: string };
    AWSDateTime: { input: string; output: string };
    AWSEmail: { input: string; output: string };
    AWSIPAddress: { input: any; output: any };
    AWSJSON: { input: string; output: string };
    AWSPhone: { input: any; output: any };
    AWSTime: { input: string; output: string };
    AWSTimestamp: { input: string; output: string };
    AWSURL: { input: string; output: string };
    BigInt: { input: any; output: any };
    Double: { input: any; output: any };
};

export enum AppointmentType {
    PhoneCall = 'PHONE_CALL',
    VideoMeeting = 'VIDEO_MEETING',
}

export type AvailabilityInput = {
    endDateTime: Scalars['AWSDateTime']['input'];
    episodeId?: InputMaybe<Scalars['String']['input']>;
    episodePracticeId?: InputMaybe<Scalars['String']['input']>;
    languageKey?: InputMaybe<Scalars['String']['input']>;
    practiceId?: InputMaybe<Scalars['String']['input']>;
    providers: Array<InputMaybe<ProviderUserInput>>;
    slotDuration: Scalars['Int']['input'];
    startDateTime: Scalars['AWSDateTime']['input'];
    tzTimeZone: Scalars['String']['input'];
    whoFilterType: Scalars['Int']['input'];
};

export type AvailabilitySettingByDate = {
    __typename?: 'AvailabilitySettingByDate';
    date: Scalars['AWSDate']['output'];
    timeslots: Array<AvailabilitySettingTimeslot>;
};

export type AvailabilitySettingByDay = {
    __typename?: 'AvailabilitySettingByDay';
    day: Scalars['String']['output'];
    timeslots: Array<AvailabilitySettingTimeslot>;
};

export type AvailabilitySettingTimeslot = {
    __typename?: 'AvailabilitySettingTimeslot';
    availabilityType: Scalars['String']['output'];
    category: Scalars['String']['output'];
    dateOfAvailability?: Maybe<Scalars['AWSDate']['output']>;
    dayOfWeek?: Maybe<Scalars['String']['output']>;
    endTime: Scalars['String']['output'];
    id: Scalars['String']['output'];
    modifiedByEC: Scalars['Boolean']['output'];
    patientId?: Maybe<Scalars['String']['output']>;
    practice?: Maybe<Practice>;
    startTime: Scalars['String']['output'];
    userId?: Maybe<Scalars['String']['output']>;
};

export type AvailabilitySettingTimeslotInput = {
    availabilityType?: InputMaybe<Scalars['String']['input']>;
    category?: InputMaybe<Scalars['String']['input']>;
    dateOfAvailability?: InputMaybe<Scalars['AWSDate']['input']>;
    dayOfWeek?: InputMaybe<Scalars['String']['input']>;
    endTime: Scalars['String']['input'];
    id?: InputMaybe<Scalars['String']['input']>;
    modifiedByEC?: InputMaybe<Scalars['Boolean']['input']>;
    patientId?: InputMaybe<Scalars['String']['input']>;
    practice?: InputMaybe<PracticeInput>;
    startTime: Scalars['String']['input'];
    userId?: InputMaybe<Scalars['String']['input']>;
};

export type AvailabilitySettings = {
    __typename?: 'AvailabilitySettings';
    general?: Maybe<AvailabilitySettingsCategoryGroup>;
    site?: Maybe<AvailabilitySettingsCategoryGroup>;
};

export type AvailabilitySettingsCategoryGroup = {
    __typename?: 'AvailabilitySettingsCategoryGroup';
    byDate: Array<AvailabilitySettingByDate>;
    byDay: Array<AvailabilitySettingByDay>;
};

export enum AvailabilityWhoFilterType {
    Team = 'TEAM',
    User = 'USER',
}

export type AvailabilityWindowInput = {
    appointmentId: Scalars['String']['input'];
};

export type CalendarEvent = {
    __typename?: 'CalendarEvent';
    endDateTime?: Maybe<Scalars['AWSDateTime']['output']>;
    eventType?: Maybe<Scalars['String']['output']>;
    id: Scalars['String']['output'];
    ownerId?: Maybe<Scalars['String']['output']>;
    reminderStatus?: Maybe<Scalars['String']['output']>;
    startDateTime?: Maybe<Scalars['AWSDateTime']['output']>;
};

export type CommunicationInformation = {
    __typename?: 'CommunicationInformation';
    canReceiveCommunications: Scalars['Boolean']['output'];
    canReceiveEmail: Scalars['Boolean']['output'];
    canReceiveSms: Scalars['Boolean']['output'];
    emailValidationResult: EmailCommunicationInformation;
    hasActiveEpisode: Scalars['Boolean']['output'];
    hasActiveEpisodeOwner: Scalars['Boolean']['output'];
    patientCanSendMessage: Scalars['Boolean']['output'];
    smsValidationResult: SmsCommunicationInformation;
};

export type ConcertGraphqlValidationError = {
    __typename?: 'ConcertGraphqlValidationError';
    errorCode: Scalars['String']['output'];
    message: Scalars['String']['output'];
};

export type Conversation = {
    __typename?: 'Conversation';
    clinicianId: Scalars['String']['output'];
    clinicianName: Scalars['String']['output'];
    creationDate: Scalars['AWSDateTime']['output'];
    episodeId: Scalars['String']['output'];
    episodeName: Scalars['String']['output'];
    hasUnopenedMessages: Scalars['Boolean']['output'];
    id: Scalars['String']['output'];
    lastActivityDate: Scalars['AWSDateTime']['output'];
    messages: Array<ConversationMessage>;
    patientHasUnopenedMessages?: Maybe<Scalars['Boolean']['output']>;
    patientId: Scalars['String']['output'];
    patientName: Scalars['String']['output'];
    patientPreferredName: Scalars['String']['output'];
    patientTimezone: Scalars['String']['output'];
    topic: Scalars['String']['output'];
};

export type ConversationInput = {
    episodeId: Scalars['String']['input'];
    message: ConversationMessageInput;
    topic: Scalars['String']['input'];
};

export type ConversationMessage = {
    __typename?: 'ConversationMessage';
    clinicalNotes: Scalars['String']['output'];
    conversationId: Scalars['String']['output'];
    createDate: Scalars['AWSDateTime']['output'];
    deleted: Scalars['Boolean']['output'];
    draft: Scalars['Boolean']['output'];
    id: Scalars['String']['output'];
    message: Scalars['String']['output'];
    minutes?: Maybe<Scalars['Int']['output']>;
    recipientId: Scalars['String']['output'];
    recipientName: Scalars['String']['output'];
    recipientType: MessageUserType;
    senderId: Scalars['String']['output'];
    senderName: Scalars['String']['output'];
    senderType: MessageUserType;
};

export type ConversationMessageInput = {
    clinicalNotes?: InputMaybe<Scalars['String']['input']>;
    conversationId?: InputMaybe<Scalars['String']['input']>;
    draft?: InputMaybe<Scalars['Boolean']['input']>;
    message: Scalars['String']['input'];
    minutes?: InputMaybe<Scalars['Float']['input']>;
    recipientId: Scalars['String']['input'];
};

export type CreateConversationResponse = ConcertGraphqlValidationError | Conversation;

export type DateAvailability = {
    __typename?: 'DateAvailability';
    date: Scalars['String']['output'];
    timeslots?: Maybe<Array<Maybe<Timeslot>>>;
};

export type EmailCommunicationInformation = {
    __typename?: 'EmailCommunicationInformation';
    hasBouncedAddress: Scalars['Boolean']['output'];
    hasOptedIn: Scalars['Boolean']['output'];
    hasValidAge: Scalars['Boolean']['output'];
    hasValidEmail: Scalars['Boolean']['output'];
};

export type GeneralSettings = {
    __typename?: 'GeneralSettings';
    isAcceptingNewPatients?: Maybe<Scalars['Boolean']['output']>;
    notAcceptingType?: Maybe<Scalars['String']['output']>;
    userConcertSetting?: Maybe<UserConcertSetting>;
};

export type GetAvailabilitySettingsInput = {
    userIds: Array<Scalars['String']['input']>;
};

export type GetCalendarFileInput = {
    description?: InputMaybe<Scalars['String']['input']>;
    duration: Scalars['Int']['input'];
    location?: InputMaybe<Scalars['String']['input']>;
    startDateTime: Scalars['AWSDateTime']['input'];
    title: Scalars['String']['input'];
};

export type GetConversationInput = {
    conversationId?: InputMaybe<Scalars['String']['input']>;
    messageId?: InputMaybe<Scalars['String']['input']>;
};

export type GetConversationResponse = {
    __typename?: 'GetConversationResponse';
    conversation?: Maybe<Conversation>;
    patientCommunicationInformation?: Maybe<CommunicationInformation>;
};

export type GetConversationsInput = {
    episodeId: Scalars['String']['input'];
    userId: Scalars['String']['input'];
};

export type GetConversationsResponse = {
    __typename?: 'GetConversationsResponse';
    conversations: Array<Conversation>;
    episodeName?: Maybe<Scalars['String']['output']>;
    episodeOwnerId?: Maybe<Scalars['String']['output']>;
    patientCommunicationInformation?: Maybe<CommunicationInformation>;
    patientId?: Maybe<Scalars['String']['output']>;
};

export type GetPatientResourceDataInput = {
    fileId: Scalars['String']['input'];
};

export type GetPatientResourcesInput = {
    episodeId: Scalars['String']['input'];
};

export type MarkConversationMessagesOpenedInput = {
    conversationId: Scalars['String']['input'];
    lastOpenedMessageCreateDate: Scalars['AWSDateTime']['input'];
};

export enum MessageUserType {
    Clinician = 'CLINICIAN',
    Patient = 'PATIENT',
}

export type Mutation = {
    __typename?: 'Mutation';
    createConversation?: Maybe<CreateConversationResponse>;
    markConversationMessagesOpened?: Maybe<Scalars['Boolean']['output']>;
    reschedule?: Maybe<RescheduleResponse>;
    schedule?: Maybe<ScheduleResponse>;
    sendConversationMessage?: Maybe<SendConversationMessageResponse>;
    updateAvailabilitySettings?: Maybe<Scalars['Boolean']['output']>;
};

export type MutationCreateConversationArgs = {
    input: ConversationInput;
};

export type MutationMarkConversationMessagesOpenedArgs = {
    input: MarkConversationMessagesOpenedInput;
};

export type MutationRescheduleArgs = {
    rescheduleRequest: RescheduleInput;
};

export type MutationScheduleArgs = {
    scheduleRequest: ScheduleInput;
};

export type MutationSendConversationMessageArgs = {
    messageInput: SendConversationMessageInput;
};

export type MutationUpdateAvailabilitySettingsArgs = {
    request: UpdateAvailabilitySettingsInput;
};

export type PatientResource = {
    __typename?: 'PatientResource';
    carePlanName?: Maybe<Scalars['String']['output']>;
    fileCreationDate?: Maybe<Scalars['String']['output']>;
    fileId?: Maybe<Scalars['String']['output']>;
    fileName?: Maybe<Scalars['String']['output']>;
};

export type Practice = {
    __typename?: 'Practice';
    id: Scalars['String']['output'];
    name: Scalars['String']['output'];
};

export type PracticeInput = {
    id: Scalars['String']['input'];
    name: Scalars['String']['input'];
};

export type ProviderAvailability = {
    __typename?: 'ProviderAvailability';
    acceptingNewPatients?: Maybe<Scalars['Boolean']['output']>;
    capacity?: Maybe<ProviderCapacity>;
    engagementTypes?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
    isOnSitePractice?: Maybe<Scalars['Boolean']['output']>;
    notAcceptingType?: Maybe<Scalars['String']['output']>;
    provider?: Maybe<User>;
    specialties?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
};

export type ProviderCapacity = {
    __typename?: 'ProviderCapacity';
    minutesAvailableInNearFuture?: Maybe<Scalars['Int']['output']>;
    timeslotsAvailableInNearFuture?: Maybe<Scalars['Int']['output']>;
};

export type ProviderUserInput = {
    doxyLink?: InputMaybe<Scalars['String']['input']>;
    hasConcertSettingsManagerAccess?: InputMaybe<Scalars['Boolean']['input']>;
    id?: InputMaybe<Scalars['String']['input']>;
    isSysAdmin?: InputMaybe<Scalars['Boolean']['input']>;
    languages?: InputMaybe<Scalars['String']['input']>;
    managerId?: InputMaybe<Scalars['String']['input']>;
    name?: InputMaybe<Scalars['String']['input']>;
    nameWithSuffix?: InputMaybe<Scalars['String']['input']>;
    numOfActiveEpisodes?: InputMaybe<Scalars['Int']['input']>;
    numOfActiveEpisodesForPractice?: InputMaybe<Scalars['Int']['input']>;
    phone?: InputMaybe<Scalars['String']['input']>;
    photoUrl?: InputMaybe<Scalars['String']['input']>;
    profileName?: InputMaybe<Scalars['String']['input']>;
    region?: InputMaybe<Scalars['String']['input']>;
    roleName?: InputMaybe<Scalars['String']['input']>;
    targetCaseLoad?: InputMaybe<Scalars['Int']['input']>;
    timezone?: InputMaybe<Scalars['String']['input']>;
    title?: InputMaybe<Scalars['String']['input']>;
};

export type Query = {
    __typename?: 'Query';
    availabilityWindow?: Maybe<Array<Scalars['AWSDateTime']['output']>>;
    getAuthenticationToken?: Maybe<Scalars['String']['output']>;
    getAvailabilitySettings?: Maybe<AvailabilitySettings>;
    getCalendarFile?: Maybe<Scalars['String']['output']>;
    getConversation?: Maybe<GetConversationResponse>;
    getConversations?: Maybe<GetConversationsResponse>;
    getPatientResourceData?: Maybe<Scalars['String']['output']>;
    getPatientResources?: Maybe<Array<Maybe<PatientResource>>>;
    rescheduleAvailability?: Maybe<Array<Maybe<DateAvailability>>>;
    scheduleAvailability?: Maybe<Array<Maybe<DateAvailability>>>;
};

export type QueryAvailabilityWindowArgs = {
    request: AvailabilityWindowInput;
};

export type QueryGetAvailabilitySettingsArgs = {
    request: GetAvailabilitySettingsInput;
};

export type QueryGetCalendarFileArgs = {
    request: GetCalendarFileInput;
};

export type QueryGetConversationArgs = {
    request: GetConversationInput;
};

export type QueryGetConversationsArgs = {
    request: GetConversationsInput;
};

export type QueryGetPatientResourceDataArgs = {
    request: GetPatientResourceDataInput;
};

export type QueryGetPatientResourcesArgs = {
    request: GetPatientResourcesInput;
};

export type QueryRescheduleAvailabilityArgs = {
    request: AvailabilityInput;
};

export type QueryScheduleAvailabilityArgs = {
    request: AvailabilityInput;
};

export type RescheduleAvailabilityInput = {
    originalAppointmentId: Scalars['String']['input'];
    tzTimeZone: Scalars['String']['input'];
};

export type RescheduleInput = {
    appointmentStartDateTime: Scalars['AWSDateTime']['input'];
    originalAppointmentId: Scalars['String']['input'];
};

export type RescheduleResponse = CalendarEvent | ConcertGraphqlValidationError;

export type ScheduleInput = {
    appointmentDuration: Scalars['Int']['input'];
    appointmentStartDateTime: Scalars['AWSDateTime']['input'];
    appointmentType: AppointmentType;
    episodeId: Scalars['String']['input'];
    providerId: Scalars['String']['input'];
};

export type ScheduleResponse = CalendarEvent | ConcertGraphqlValidationError;

export type SendConversationMessageResponse = ConcertGraphqlValidationError | ConversationMessage;

export type SmsCommunicationInformation = {
    __typename?: 'SmsCommunicationInformation';
    hasOptedIn: Scalars['Boolean']['output'];
    hasPhoneNumber: Scalars['Boolean']['output'];
};

export type Timeslot = {
    __typename?: 'Timeslot';
    providerAvailability?: Maybe<Array<Maybe<ProviderAvailability>>>;
    time?: Maybe<Scalars['AWSTime']['output']>;
};

export type UpdateAvailabilitySettingsInput = {
    settingsToBeAdded: Array<AvailabilitySettingTimeslotInput>;
    settingsToBeDeleted: Array<AvailabilitySettingTimeslotInput>;
    settingsToBeModified: Array<AvailabilitySettingTimeslotInput>;
};

export type User = {
    __typename?: 'User';
    caseloadAllocation?: Maybe<Scalars['Int']['output']>;
    doxyLink?: Maybe<Scalars['String']['output']>;
    generalSettings?: Maybe<GeneralSettings>;
    hasConcertSettingsManagerAccess?: Maybe<Scalars['Boolean']['output']>;
    id?: Maybe<Scalars['String']['output']>;
    isSysAdmin?: Maybe<Scalars['Boolean']['output']>;
    languages?: Maybe<Scalars['String']['output']>;
    managerId?: Maybe<Scalars['String']['output']>;
    name?: Maybe<Scalars['String']['output']>;
    nameWithSuffix?: Maybe<Scalars['String']['output']>;
    numOfActiveEpisodes?: Maybe<Scalars['Int']['output']>;
    numOfActiveEpisodesForPractice?: Maybe<Scalars['Int']['output']>;
    phone?: Maybe<Scalars['String']['output']>;
    photoUrl?: Maybe<Scalars['String']['output']>;
    profileName?: Maybe<Scalars['String']['output']>;
    region?: Maybe<Scalars['String']['output']>;
    roleName?: Maybe<Scalars['String']['output']>;
    targetCaseLoad?: Maybe<Scalars['Int']['output']>;
    timezone?: Maybe<Scalars['String']['output']>;
    title?: Maybe<Scalars['String']['output']>;
};

export type UserConcertSetting = {
    __typename?: 'UserConcertSetting';
    acceptingNewPatients?: Maybe<Scalars['Boolean']['output']>;
    activeEpisodes?: Maybe<Scalars['Float']['output']>;
    engagementTypes?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
    specialties?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
    targetCaseLoad?: Maybe<Scalars['Float']['output']>;
    userId?: Maybe<Scalars['String']['output']>;
};

export type SendConversationMessageInput = {
    conversationId: Scalars['String']['input'];
    message: ConversationMessageInput;
};

export type GetAuthenticationTokenQueryVariables = Exact<{ [key: string]: never }>;

export type GetAuthenticationTokenQuery = { __typename?: 'Query'; getAuthenticationToken?: string | null };

export type ScheduleAvailabilityQueryVariables = Exact<{
    request: AvailabilityInput;
}>;

export type ScheduleAvailabilityQuery = {
    __typename?: 'Query';
    scheduleAvailability?: Array<{
        __typename?: 'DateAvailability';
        date: string;
        timeslots?: Array<{
            __typename?: 'Timeslot';
            time?: string | null;
            providerAvailability?: Array<{
                __typename?: 'ProviderAvailability';
                acceptingNewPatients?: boolean | null;
                engagementTypes?: Array<string | null> | null;
                isOnSitePractice?: boolean | null;
                notAcceptingType?: string | null;
                specialties?: Array<string | null> | null;
                capacity?: { __typename?: 'ProviderCapacity'; timeslotsAvailableInNearFuture?: number | null } | null;
                provider?: {
                    __typename?: 'User';
                    id?: string | null;
                    name?: string | null;
                    nameWithSuffix?: string | null;
                    doxyLink?: string | null;
                    hasConcertSettingsManagerAccess?: boolean | null;
                    isSysAdmin?: boolean | null;
                    languages?: string | null;
                    managerId?: string | null;
                    numOfActiveEpisodes?: number | null;
                    numOfActiveEpisodesForPractice?: number | null;
                    caseloadAllocation?: number | null;
                    phone?: string | null;
                    photoUrl?: string | null;
                    profileName?: string | null;
                    region?: string | null;
                    roleName?: string | null;
                    title?: string | null;
                    targetCaseLoad?: number | null;
                    timezone?: string | null;
                    generalSettings?: {
                        __typename?: 'GeneralSettings';
                        isAcceptingNewPatients?: boolean | null;
                        notAcceptingType?: string | null;
                    } | null;
                } | null;
            } | null> | null;
        } | null> | null;
    } | null> | null;
};

export type GetConversationsQueryVariables = Exact<{
    request: GetConversationsInput;
}>;

export type GetConversationsQuery = {
    __typename?: 'Query';
    getConversations?: {
        __typename?: 'GetConversationsResponse';
        episodeName?: string | null;
        patientId?: string | null;
        episodeOwnerId?: string | null;
        patientCommunicationInformation?: {
            __typename?: 'CommunicationInformation';
            canReceiveCommunications: boolean;
            hasActiveEpisode: boolean;
        } | null;
        conversations: Array<{
            __typename?: 'Conversation';
            creationDate: string;
            episodeName: string;
            episodeId: string;
            id: string;
            lastActivityDate: string;
            patientName: string;
            patientTimezone: string;
            patientId: string;
            clinicianName: string;
            topic: string;
            hasUnopenedMessages: boolean;
            patientHasUnopenedMessages?: boolean | null;
        }>;
    } | null;
};

export type GetConversationQueryVariables = Exact<{
    request: GetConversationInput;
}>;

export type GetConversationQuery = {
    __typename?: 'Query';
    getConversation?: {
        __typename?: 'GetConversationResponse';
        patientCommunicationInformation?: {
            __typename?: 'CommunicationInformation';
            canReceiveCommunications: boolean;
            hasActiveEpisode: boolean;
        } | null;
        conversation?: {
            __typename?: 'Conversation';
            creationDate: string;
            episodeId: string;
            episodeName: string;
            patientId: string;
            id: string;
            lastActivityDate: string;
            patientName: string;
            clinicianId: string;
            clinicianName: string;
            topic: string;
            patientPreferredName: string;
            patientTimezone: string;
            hasUnopenedMessages: boolean;
            patientHasUnopenedMessages?: boolean | null;
            messages: Array<{
                __typename?: 'ConversationMessage';
                id: string;
                conversationId: string;
                message: string;
                minutes?: number | null;
                recipientId: string;
                senderId: string;
                senderType: MessageUserType;
                recipientType: MessageUserType;
                createDate: string;
                senderName: string;
                recipientName: string;
                draft: boolean;
                deleted: boolean;
                clinicalNotes: string;
            }>;
        } | null;
    } | null;
};

export type CreateConversationMutationVariables = Exact<{
    input: ConversationInput;
}>;

export type CreateConversationMutation = {
    __typename?: 'Mutation';
    createConversation?:
        | { __typename: 'ConcertGraphqlValidationError'; message: string; errorCode: string }
        | {
              __typename: 'Conversation';
              id: string;
              topic: string;
              episodeId: string;
              patientName: string;
              episodeName: string;
              clinicianName: string;
              creationDate: string;
              patientId: string;
              lastActivityDate: string;
              messages: Array<{
                  __typename?: 'ConversationMessage';
                  id: string;
                  conversationId: string;
                  message: string;
                  recipientId: string;
                  senderId: string;
                  senderType: MessageUserType;
                  recipientType: MessageUserType;
                  createDate: string;
                  senderName: string;
                  recipientName: string;
              }>;
          }
        | null;
};

export type SendConversationMessageMutationVariables = Exact<{
    input: SendConversationMessageInput;
}>;

export type SendConversationMessageMutation = {
    __typename?: 'Mutation';
    sendConversationMessage?:
        | { __typename: 'ConcertGraphqlValidationError'; message: string; errorCode: string }
        | {
              __typename: 'ConversationMessage';
              id: string;
              conversationId: string;
              message: string;
              recipientId: string;
              senderId: string;
              senderType: MessageUserType;
              recipientType: MessageUserType;
              createDate: string;
              senderName: string;
              recipientName: string;
              draft: boolean;
              deleted: boolean;
              clinicalNotes: string;
          }
        | null;
};

export type MarkConversationMessagesOpenedMutationVariables = Exact<{
    input: MarkConversationMessagesOpenedInput;
}>;

export type MarkConversationMessagesOpenedMutation = {
    __typename?: 'Mutation';
    markConversationMessagesOpened?: boolean | null;
};

export type GetUserAvailabilitySettingsQueryVariables = Exact<{
    request: GetAvailabilitySettingsInput;
}>;

export type GetUserAvailabilitySettingsQuery = {
    __typename?: 'Query';
    getAvailabilitySettings?: {
        __typename?: 'AvailabilitySettings';
        general?: {
            __typename?: 'AvailabilitySettingsCategoryGroup';
            byDate: Array<{
                __typename?: 'AvailabilitySettingByDate';
                date: string;
                timeslots: Array<{
                    __typename?: 'AvailabilitySettingTimeslot';
                    startTime: string;
                    endTime: string;
                    dayOfWeek?: string | null;
                    userId?: string | null;
                    patientId?: string | null;
                    availabilityType: string;
                    id: string;
                    category: string;
                    dateOfAvailability?: string | null;
                    modifiedByEC: boolean;
                    practice?: { __typename?: 'Practice'; id: string; name: string } | null;
                }>;
            }>;
            byDay: Array<{
                __typename?: 'AvailabilitySettingByDay';
                day: string;
                timeslots: Array<{
                    __typename?: 'AvailabilitySettingTimeslot';
                    startTime: string;
                    endTime: string;
                    dayOfWeek?: string | null;
                    userId?: string | null;
                    patientId?: string | null;
                    availabilityType: string;
                    id: string;
                    category: string;
                    dateOfAvailability?: string | null;
                    modifiedByEC: boolean;
                    practice?: { __typename?: 'Practice'; id: string; name: string } | null;
                }>;
            }>;
        } | null;
        site?: {
            __typename?: 'AvailabilitySettingsCategoryGroup';
            byDate: Array<{
                __typename?: 'AvailabilitySettingByDate';
                date: string;
                timeslots: Array<{
                    __typename?: 'AvailabilitySettingTimeslot';
                    startTime: string;
                    endTime: string;
                    dayOfWeek?: string | null;
                    userId?: string | null;
                    patientId?: string | null;
                    availabilityType: string;
                    id: string;
                    category: string;
                    dateOfAvailability?: string | null;
                    modifiedByEC: boolean;
                    practice?: { __typename?: 'Practice'; id: string; name: string } | null;
                }>;
            }>;
            byDay: Array<{
                __typename?: 'AvailabilitySettingByDay';
                day: string;
                timeslots: Array<{
                    __typename?: 'AvailabilitySettingTimeslot';
                    startTime: string;
                    endTime: string;
                    dayOfWeek?: string | null;
                    userId?: string | null;
                    patientId?: string | null;
                    availabilityType: string;
                    id: string;
                    category: string;
                    dateOfAvailability?: string | null;
                    modifiedByEC: boolean;
                    practice?: { __typename?: 'Practice'; id: string; name: string } | null;
                }>;
            }>;
        } | null;
    } | null;
};

export type GetGeneralUserAvailabilitySettingsByDateQueryVariables = Exact<{
    request: GetAvailabilitySettingsInput;
}>;

export type GetGeneralUserAvailabilitySettingsByDateQuery = {
    __typename?: 'Query';
    getAvailabilitySettings?: {
        __typename?: 'AvailabilitySettings';
        general?: {
            __typename?: 'AvailabilitySettingsCategoryGroup';
            byDate: Array<{
                __typename?: 'AvailabilitySettingByDate';
                date: string;
                timeslots: Array<{
                    __typename?: 'AvailabilitySettingTimeslot';
                    startTime: string;
                    endTime: string;
                    dayOfWeek?: string | null;
                    userId?: string | null;
                    patientId?: string | null;
                    availabilityType: string;
                    id: string;
                    category: string;
                    dateOfAvailability?: string | null;
                    modifiedByEC: boolean;
                    practice?: { __typename?: 'Practice'; id: string; name: string } | null;
                }>;
            }>;
        } | null;
    } | null;
};

export type GetPatientAvailabilitySettingsQueryVariables = Exact<{
    request: GetAvailabilitySettingsInput;
}>;

export type GetPatientAvailabilitySettingsQuery = {
    __typename?: 'Query';
    getAvailabilitySettings?: {
        __typename?: 'AvailabilitySettings';
        general?: {
            __typename?: 'AvailabilitySettingsCategoryGroup';
            byDate: Array<{
                __typename?: 'AvailabilitySettingByDate';
                date: string;
                timeslots: Array<{
                    __typename?: 'AvailabilitySettingTimeslot';
                    startTime: string;
                    endTime: string;
                    dayOfWeek?: string | null;
                    userId?: string | null;
                    patientId?: string | null;
                    availabilityType: string;
                    id: string;
                    category: string;
                    dateOfAvailability?: string | null;
                    modifiedByEC: boolean;
                    practice?: { __typename?: 'Practice'; id: string; name: string } | null;
                }>;
            }>;
            byDay: Array<{
                __typename?: 'AvailabilitySettingByDay';
                day: string;
                timeslots: Array<{
                    __typename?: 'AvailabilitySettingTimeslot';
                    startTime: string;
                    endTime: string;
                    dayOfWeek?: string | null;
                    userId?: string | null;
                    patientId?: string | null;
                    availabilityType: string;
                    id: string;
                    category: string;
                    dateOfAvailability?: string | null;
                    modifiedByEC: boolean;
                    practice?: { __typename?: 'Practice'; id: string; name: string } | null;
                }>;
            }>;
        } | null;
    } | null;
};

export type GetGeneralUserAvailabilitySettingsByDayQueryVariables = Exact<{
    request: GetAvailabilitySettingsInput;
}>;

export type GetGeneralUserAvailabilitySettingsByDayQuery = {
    __typename?: 'Query';
    getAvailabilitySettings?: {
        __typename?: 'AvailabilitySettings';
        general?: {
            __typename?: 'AvailabilitySettingsCategoryGroup';
            byDay: Array<{
                __typename?: 'AvailabilitySettingByDay';
                day: string;
                timeslots: Array<{
                    __typename?: 'AvailabilitySettingTimeslot';
                    startTime: string;
                    endTime: string;
                    dayOfWeek?: string | null;
                    userId?: string | null;
                    patientId?: string | null;
                    availabilityType: string;
                    id: string;
                    category: string;
                    dateOfAvailability?: string | null;
                    modifiedByEC: boolean;
                    practice?: { __typename?: 'Practice'; id: string; name: string } | null;
                }>;
            }>;
        } | null;
    } | null;
};

export type UpdateAvailabilitySettingsMutationVariables = Exact<{
    request: UpdateAvailabilitySettingsInput;
}>;

export type UpdateAvailabilitySettingsMutation = {
    __typename?: 'Mutation';
    updateAvailabilitySettings?: boolean | null;
};

export const GetAuthenticationTokenDocument = `
    query getAuthenticationToken {
  getAuthenticationToken
}
    `;
export const ScheduleAvailabilityDocument = `
    query scheduleAvailability($request: AvailabilityInput!) {
  scheduleAvailability(request: $request) {
    date
    timeslots {
      time
      providerAvailability {
        capacity {
          timeslotsAvailableInNearFuture
        }
        acceptingNewPatients
        engagementTypes
        isOnSitePractice
        notAcceptingType
        specialties
        provider {
          id
          name
          nameWithSuffix
          doxyLink
          hasConcertSettingsManagerAccess
          isSysAdmin
          languages
          managerId
          numOfActiveEpisodes
          numOfActiveEpisodesForPractice
          caseloadAllocation
          phone
          photoUrl
          profileName
          region
          roleName
          title
          targetCaseLoad
          timezone
          generalSettings {
            isAcceptingNewPatients
            notAcceptingType
          }
        }
      }
    }
  }
}
    `;
export const GetConversationsDocument = `
    query getConversations($request: GetConversationsInput!) {
  getConversations(request: $request) {
    episodeName
    patientId
    episodeOwnerId
    patientCommunicationInformation {
      canReceiveCommunications
      hasActiveEpisode
    }
    conversations {
      creationDate
      episodeName
      episodeId
      id
      lastActivityDate
      patientName
      patientTimezone
      patientId
      clinicianName
      topic
      hasUnopenedMessages
      patientHasUnopenedMessages
    }
  }
}
    `;
export const GetConversationDocument = `
    query getConversation($request: GetConversationInput!) {
  getConversation(request: $request) {
    patientCommunicationInformation {
      canReceiveCommunications
      hasActiveEpisode
    }
    conversation {
      creationDate
      episodeId
      episodeName
      patientId
      id
      lastActivityDate
      patientName
      clinicianId
      clinicianName
      topic
      patientPreferredName
      patientTimezone
      hasUnopenedMessages
      patientHasUnopenedMessages
      messages {
        id
        conversationId
        message
        minutes
        recipientId
        senderId
        senderType
        recipientType
        createDate
        senderName
        recipientName
        draft
        deleted
        clinicalNotes
      }
    }
  }
}
    `;
export const CreateConversationDocument = `
    mutation createConversation($input: ConversationInput!) {
  createConversation(input: $input) {
    __typename
    ... on Conversation {
      id
      topic
      episodeId
      patientName
      episodeName
      clinicianName
      creationDate
      patientId
      lastActivityDate
      messages {
        id
        conversationId
        message
        recipientId
        senderId
        senderType
        recipientType
        createDate
        senderName
        recipientName
      }
    }
    ... on ConcertGraphqlValidationError {
      message
      errorCode
    }
  }
}
    `;
export const SendConversationMessageDocument = `
    mutation sendConversationMessage($input: sendConversationMessageInput!) {
  sendConversationMessage(messageInput: $input) {
    __typename
    ... on ConversationMessage {
      id
      conversationId
      message
      recipientId
      senderId
      senderType
      recipientType
      createDate
      senderName
      recipientName
      draft
      deleted
      clinicalNotes
    }
    ... on ConcertGraphqlValidationError {
      message
      errorCode
    }
  }
}
    `;
export const MarkConversationMessagesOpenedDocument = `
    mutation markConversationMessagesOpened($input: MarkConversationMessagesOpenedInput!) {
  markConversationMessagesOpened(input: $input)
}
    `;
export const GetUserAvailabilitySettingsDocument = `
    query getUserAvailabilitySettings($request: GetAvailabilitySettingsInput!) {
  getAvailabilitySettings(request: $request) {
    general {
      byDate {
        date
        timeslots {
          startTime
          endTime
          dayOfWeek
          userId
          patientId
          availabilityType
          id
          category
          dateOfAvailability
          practice {
            id
            name
          }
          modifiedByEC
        }
      }
      byDay {
        day
        timeslots {
          startTime
          endTime
          dayOfWeek
          userId
          patientId
          availabilityType
          id
          category
          dateOfAvailability
          practice {
            id
            name
          }
          modifiedByEC
        }
      }
    }
    site {
      byDate {
        date
        timeslots {
          startTime
          endTime
          dayOfWeek
          userId
          patientId
          availabilityType
          id
          category
          dateOfAvailability
          practice {
            id
            name
          }
          modifiedByEC
        }
      }
      byDay {
        day
        timeslots {
          startTime
          endTime
          dayOfWeek
          userId
          patientId
          availabilityType
          id
          category
          dateOfAvailability
          practice {
            id
            name
          }
          modifiedByEC
        }
      }
    }
  }
}
    `;
export const GetGeneralUserAvailabilitySettingsByDateDocument = `
    query getGeneralUserAvailabilitySettingsByDate($request: GetAvailabilitySettingsInput!) {
  getAvailabilitySettings(request: $request) {
    general {
      byDate {
        date
        timeslots {
          startTime
          endTime
          dayOfWeek
          userId
          patientId
          availabilityType
          id
          category
          dateOfAvailability
          practice {
            id
            name
          }
          modifiedByEC
        }
      }
    }
  }
}
    `;
export const GetPatientAvailabilitySettingsDocument = `
    query getPatientAvailabilitySettings($request: GetAvailabilitySettingsInput!) {
  getAvailabilitySettings(request: $request) {
    general {
      byDate {
        date
        timeslots {
          startTime
          endTime
          dayOfWeek
          userId
          patientId
          availabilityType
          id
          category
          dateOfAvailability
          practice {
            id
            name
          }
          modifiedByEC
        }
      }
      byDay {
        day
        timeslots {
          startTime
          endTime
          dayOfWeek
          userId
          patientId
          availabilityType
          id
          category
          dateOfAvailability
          practice {
            id
            name
          }
          modifiedByEC
        }
      }
    }
  }
}
    `;
export const GetGeneralUserAvailabilitySettingsByDayDocument = `
    query getGeneralUserAvailabilitySettingsByDay($request: GetAvailabilitySettingsInput!) {
  getAvailabilitySettings(request: $request) {
    general {
      byDay {
        day
        timeslots {
          startTime
          endTime
          dayOfWeek
          userId
          patientId
          availabilityType
          id
          category
          dateOfAvailability
          practice {
            id
            name
          }
          modifiedByEC
        }
      }
    }
  }
}
    `;
export const UpdateAvailabilitySettingsDocument = `
    mutation updateAvailabilitySettings($request: UpdateAvailabilitySettingsInput!) {
  updateAvailabilitySettings(request: $request)
}
    `;

const injectedRtkApi = api.injectEndpoints({
    endpoints: (build) => ({
        getAuthenticationToken: build.query<GetAuthenticationTokenQuery, GetAuthenticationTokenQueryVariables | void>({
            query: (variables) => ({ document: GetAuthenticationTokenDocument, variables }),
        }),
        scheduleAvailability: build.query<ScheduleAvailabilityQuery, ScheduleAvailabilityQueryVariables>({
            query: (variables) => ({ document: ScheduleAvailabilityDocument, variables }),
        }),
        getConversations: build.query<GetConversationsQuery, GetConversationsQueryVariables>({
            query: (variables) => ({ document: GetConversationsDocument, variables }),
        }),
        getConversation: build.query<GetConversationQuery, GetConversationQueryVariables>({
            query: (variables) => ({ document: GetConversationDocument, variables }),
        }),
        createConversation: build.mutation<CreateConversationMutation, CreateConversationMutationVariables>({
            query: (variables) => ({ document: CreateConversationDocument, variables }),
        }),
        sendConversationMessage: build.mutation<
            SendConversationMessageMutation,
            SendConversationMessageMutationVariables
        >({
            query: (variables) => ({ document: SendConversationMessageDocument, variables }),
        }),
        markConversationMessagesOpened: build.mutation<
            MarkConversationMessagesOpenedMutation,
            MarkConversationMessagesOpenedMutationVariables
        >({
            query: (variables) => ({ document: MarkConversationMessagesOpenedDocument, variables }),
        }),
        getUserAvailabilitySettings: build.query<
            GetUserAvailabilitySettingsQuery,
            GetUserAvailabilitySettingsQueryVariables
        >({
            query: (variables) => ({ document: GetUserAvailabilitySettingsDocument, variables }),
        }),
        getGeneralUserAvailabilitySettingsByDate: build.query<
            GetGeneralUserAvailabilitySettingsByDateQuery,
            GetGeneralUserAvailabilitySettingsByDateQueryVariables
        >({
            query: (variables) => ({ document: GetGeneralUserAvailabilitySettingsByDateDocument, variables }),
        }),
        getPatientAvailabilitySettings: build.query<
            GetPatientAvailabilitySettingsQuery,
            GetPatientAvailabilitySettingsQueryVariables
        >({
            query: (variables) => ({ document: GetPatientAvailabilitySettingsDocument, variables }),
        }),
        getGeneralUserAvailabilitySettingsByDay: build.query<
            GetGeneralUserAvailabilitySettingsByDayQuery,
            GetGeneralUserAvailabilitySettingsByDayQueryVariables
        >({
            query: (variables) => ({ document: GetGeneralUserAvailabilitySettingsByDayDocument, variables }),
        }),
        updateAvailabilitySettings: build.mutation<
            UpdateAvailabilitySettingsMutation,
            UpdateAvailabilitySettingsMutationVariables
        >({
            query: (variables) => ({ document: UpdateAvailabilitySettingsDocument, variables }),
        }),
    }),
});

export { injectedRtkApi as api };
export const {
    useGetAuthenticationTokenQuery,
    useLazyGetAuthenticationTokenQuery,
    useScheduleAvailabilityQuery,
    useLazyScheduleAvailabilityQuery,
    useGetConversationsQuery,
    useLazyGetConversationsQuery,
    useGetConversationQuery,
    useLazyGetConversationQuery,
    useCreateConversationMutation,
    useSendConversationMessageMutation,
    useMarkConversationMessagesOpenedMutation,
    useGetUserAvailabilitySettingsQuery,
    useLazyGetUserAvailabilitySettingsQuery,
    useGetGeneralUserAvailabilitySettingsByDateQuery,
    useLazyGetGeneralUserAvailabilitySettingsByDateQuery,
    useGetPatientAvailabilitySettingsQuery,
    useLazyGetPatientAvailabilitySettingsQuery,
    useGetGeneralUserAvailabilitySettingsByDayQuery,
    useLazyGetGeneralUserAvailabilitySettingsByDayQuery,
    useUpdateAvailabilitySettingsMutation,
} = injectedRtkApi;
